import {
  DEFAULT_ARTICLE_META_IMAGE, metaImageDimensions,
} from './constants';
import getAuthorSlug from './getAuthorSlug';
import Routes from '../routes.mjs';
import { AccessLevel } from './graphql/api/graphql';

const getArticleJsonLdData = (article, url, origin) => {
  const {
    imageUrls, title, createdAt, updatedAt, description, accessLevel,
  } = article;
  const metaImageUrl = imageUrls[0];
  const absoluteImageUrl = `${metaImageUrl || DEFAULT_ARTICLE_META_IMAGE}`;
  const creator = { ...article.creator };

  const authorSlug = creator?.email ? getAuthorSlug(creator.email) : null;
  const authorUrl = authorSlug
    ? Routes.findAndGetUrls('author', { authorSlug })?.urls?.as
    : null;

  const jsonLdData = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: title,
    image: {
      '@type': 'ImageObject',
      url: absoluteImageUrl,
      width: metaImageDimensions.width,
      height: metaImageDimensions.height,
    },
    datePublished: createdAt,
    dateModified: updatedAt,
    publisher: {
      '@type': 'Organization',
      name: 'FashionUnited',
      logo: {
        '@type': 'ImageObject',
        url: 'https://media.fashionunited.com/media/logo/logo-600x51.png',
        width: 600,
        height: 51,
      },
    },
    description,
    ...(authorUrl ? {
      author: {
        '@type': 'Person',
        name: creator.name,
        url: `${origin}${authorUrl}`,
      },
    } : {}),
    ...(accessLevel === AccessLevel.FREE ? {} : {
      isAccessibleForFree: false,
      hasPart: {
        '@type': 'WebPageElement',
        isAccessibleForFree: false,
        cssSelector: '.member-content',
      },
    }),
  };

  return JSON.stringify(jsonLdData);
};

export default getArticleJsonLdData;
